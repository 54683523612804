/* You can add global styles to this file, and also import other style files */
@import './variables';
@import "./mixins";
// @import 'bootstrap';

@font-face {
  font-family: "dotsfont";
  src: url("/assets/webfonts/dotsfont/dotsfont.eot");
  src: url("/assets/webfonts/dotsfont/dotsfont.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/dotsfont/dotsfont.woff") format("woff"),
    url("/assets/webfonts/dotsfont/dotsfont.ttf") format("truetype"),
    url("/assets/webfonts/dotsfont/dotsfont.svg#dotsfontregular") format("svg");
}

html,
body {
  height: 100vh;
  // overflow: hidden;
}

.full-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fill-height {
  flex-grow: 1;
  width: 100%;
}

.popover {
  max-width: 1400px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
}

.main-content-portal {
  width: 100%;
  height: 100%; // background: url(/assets/img/identity/_default/old_garage_bwblur.jpg) no-repeat center fixed;
}

.main-content-wwp {
  width: 100%;
  height: 100%;
}

.clean-input::-ms-clear {
  display: none;
}

.scroll-container {
  position: relative;
  height: 100%;
  overflow: auto;
}

.debug-data {
  color: #00ff00;
}

.text-low {
  color: #ccc !important;
}

.text-ok {
  color: #00aa00;
}

.text-letop {
  color: #ffaa00;
}

.text-alarm {
  color: #ff0000;
}

.small {
  font-size: smaller;
}

.pointer {
  cursor: pointer;
}

.normal-cursor {
  cursor: default;
}

.search-cursor,
.zoom-cursor {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.cursor-default {
  cursor: default;
}

.move {
  cursor: move;
}

// .no-outline:focus {
//   outline: 0;
// }

:focus {
  outline: 0 !important;
  // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.grow {
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.grow::after {
  content: "";
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  bottom: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.grow:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  z-index: 2;
}

.grow:hover::after {
  opacity: 1;
  z-index: 1;
}

.btn-xsm,
.btn-group-xsm>.btn {
  padding: 3px 5px !important;
  font-size: 10px !important;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 0.25rem 0.4rem !important;
  font-size: 0.875rem !important;
  line-height: 0.5 !important;
}

.btn-group-xxs>.btn,
.btn-xxs {
  padding: 0.1rem 0.2rem !important;
  font-size: 0.875rem !important;
  line-height: 0.1 !important;
}

.label-admin {
  font-weight: 700;
  font-size: 14px;
}

.collapse-button {
  transition: transform 300ms ease-in-out;
  transform: rotate(0deg);
  cursor: pointer;
  display: inline-block;
}

.is-collapsed {
  transform: rotate(-180deg);
}

.animate-button {
  transition: transform 300ms ease-in-out;
  transform: rotate(0deg);
}

.animate-360 {
  transform: rotate(360deg);
}

.border-low {
  border: 1px solid #ced4da;
}

.checkbox-large {
  transform: scale(1.6);
  -webkit-transform: scale(1.6);
  margin-left: -16px !important;
  margin-top: -8px;
}

.breadcrumb-webshop {
  height: 48px;
}

// .modal-content {
//   background-color: rgb(255, 255, 255) !important;
// }
.callback-modal {
  top: 35%;
}

.modal-md {
  max-width: 600px !important;
}

.modal-xxl {
  max-width: 950px !important;
}

.modal-max {
  max-width: 99% !important;
}

.trans-back-090 {
  background-color: rgba(255, 255, 255, 0.9);
}

.alt-back {
  background-color: rgba(0, 0, 0, 0.04);
}

.part-template th,
.part-template td {
  border-top: 1px solid transparent !important;
}

.table-fix-margin {
  margin-bottom: 0;
}

.table-fix-striped>tbody>tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-fix-hover>tbody>tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-fix {
  border-radius: 4px 4px 0 0;
  margin: 0;
  position: relative;
  top: -1px;
  font-size: smaller;
}

.table-fix td {
  vertical-align: middle;
  padding: 2px 6px;
}

.table-fix-row-odd {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-fix-row-no-border td {
  border-color: transparent !important;
}

.table-vertical-border td:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-detail-row {
  border-top: 1px solid #ddd;
  font-size: smaller;
}

.table-detail-row-vertical-border>td:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-detail-row-odd {
  background-color: #f5f5f5;
}

.table-detail-row>td {
  padding: 5px;
}

.pop-warning {
  max-width: 450px !important;
}

.loading {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
